<template>
    <div class="h5-logo">
        <a href="javascript:;"></a>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LogoH5',
});
</script>

<style lang="scss" scoped>
.h5-logo {
    width: 204px;
    height: 159px;
    a {
        display: block;
        text-decoration: none;
        background-image: url(../../assets/luck77/logo.png);
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-position: center top;
        background-repeat: no-repeat;
    }
}
</style>