<template>
    <div class="h5-game-banner">
        
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'H5GameBanner',
});
</script>

<style lang="scss" scoped>
.h5-game-banner {
    margin: 48px auto 0;
    width: 710px;
    height: 212px;
    background: url(../../assets/luck77/game-banner.png) center top no-repeat;
    background-size: 100% 100%;
}
</style>