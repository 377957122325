export const isIos = () => {
    return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
}

export const isAndroid = () => {
    return /(Android)/i.test(navigator.userAgent)
}

export const downloadApp = () => {
    if (isIos()) {
        window.open('/app/pklubdtf.mobileconfig')
    }
    else if (isAndroid()) {
        window.open('/app/pklubdtf.apk')
        // window.open('https://images.1097638.com/wsd-images-prod/pklubdtf4/merchant_resource/android/pklubdtf4_2.4.50_20240811001549_20240811002147.apk')
    } else {
        window.open('/app/pklubdtf.apk')
        // window.open('https://images.1097638.com/wsd-images-prod/pklubdtf4/merchant_resource/android/pklubdtf4_2.4.50_20240811001549_20240811002147.apk')
    }
}
