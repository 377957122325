<template>
    <div class="pk-main">
        <H5Banner />
        <H5LuckStar />
        <H5GameBanner />
        <H5HotList />
        <DownloadFooter />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import H5Banner from '@/components/Pkluck777/h5_banner.vue'
import H5LuckStar from '@/components/Pkluck777/pk_luck_star.vue'
import H5GameBanner from '@/components/Pkluck777/h5_game_banner.vue'
import H5HotList from '@/components/Pkluck777/H5_hot_list.vue'
import DownloadFooter from '@/components/Pkluck777/download_footer.vue'

export default defineComponent({
  name: 'Pkluck777',
  components: {
    H5Banner,
    H5GameBanner,
    H5LuckStar,
    H5HotList,
    DownloadFooter,
  }
  
});
</script>

<style lang="scss" scoped>
.pk-main {
    width: 750px;
    height: 2312px;
    margin: 0 auto;
    overflow: hidden;
    background: url(../../assets/luck77/bj.png) center top no-repeat;
    background-size: 100% 100%;
    
}
</style>
