import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9364ae62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pk-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_H5Banner = _resolveComponent("H5Banner")!
  const _component_H5LuckStar = _resolveComponent("H5LuckStar")!
  const _component_H5GameBanner = _resolveComponent("H5GameBanner")!
  const _component_H5HotList = _resolveComponent("H5HotList")!
  const _component_DownloadFooter = _resolveComponent("DownloadFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_H5Banner),
    _createVNode(_component_H5LuckStar),
    _createVNode(_component_H5GameBanner),
    _createVNode(_component_H5HotList),
    _createVNode(_component_DownloadFooter)
  ]))
}